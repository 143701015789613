import {
  Container,
  Grid,
  Box,
  Typography,
  makeStyles,
  useTheme,
  Divider,
  Button,
} from "@material-ui/core"

import { lighten } from "@material-ui/core/styles"
import * as React from "react"
import ImageWithLazyload from "../../utils/components/ImageWithLazyload"

const useChart = color => {
  const theme = useTheme()
  const chartColor = {
    Green: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.yellow,
      link: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
    },
    Pink: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      link: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
    },
    Yellow: {
      backgroundColor: theme.palette.yellow,
      color: theme.palette.secondary.main,
      link: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.yellow,
      },
    },
  }

  return chartColor[color || "Green"]
}
const Element = ({ isButton, children, ...props }) => {
  if (isButton) {
    return (
      <a {...props} style={{ cursor: "pointer" }}>
        {children}
      </a>
    )
  }
  const { href, ...rest } = props
  return <div {...rest}>{children}</div>
}

function Item({ link, chart, handleHover, index, indexOpen }) {
  const subBlockChart = useChart(link.subItemBlock?.backgroundColor)
  const classes = useStyles({ chart, subBlockChart })
  const linkHref = link.url === "/" ? "/" : `/${link.url}`
  return (
    <Element
      isButton={Boolean(link.url)}
      key={index}
      href={linkHref}
      className={classes.item}
      onMouseLeave={handleHover(index)}
      onMouseEnter={handleHover(index)}
    >
      <div className={classes.linkTitle}>
        {link.title}
        {link.picto && <img width={24} src={link.picto.publicURL} />}
      </div>
      {indexOpen === index && link.menuItem?.length > 0 && (
        <div className={classes.subMenu}>
          <Container maxWidth="md">
            <Grid container alignItems="flex-start">
              <Grid item xs={8} container>
                {link.menuItem?.map((item, index) => (
                  <Grid item xs={6} key={index}>
                    <Box m={1} className={classes.link}>
                      {item.icon && (
                        <ImageWithLazyload
                          src={item.icon}
                          className={classes.icon}
                          alt="icon"
                        />
                      )}
                      <Box ml={3}>
                        <a href={item.link}>
                          <Typography
                            color="secondary"
                            variant="body1"
                            className={classes.title}
                          >
                            <b>{item.title}</b>
                          </Typography>
                          {item.subTitle && (
                            <Typography
                              variant="body2"
                              className={classes.subtitle}
                            >
                              {item.subTitle}
                            </Typography>
                          )}
                        </a>
                        {item.subLinks &&
                          item.subLinks?.map((item, index) => (
                            <a href={item.url} key={index}>
                              <Box py="4px">
                                <Typography
                                  variant="body2"
                                  className={classes.subtitle}
                                >
                                  {item.title}
                                </Typography>
                              </Box>
                            </a>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={4}>
                {link.subItemBlock && (
                  <Box p={2} className={classes.subBlock}>
                    <Typography variant="h1">
                      {link.subItemBlock.title}
                    </Typography>
                    <Typography className={classes.subtitleText}>
                      {link.subItemBlock.subTitle}
                    </Typography>
                    <Box mt={2} mb={2}>
                      <Divider className={classes.divider} />
                    </Box>
                    <Grid container justify="flex-end">
                      {link.subItemBlock.link && (
                        <Grid item>
                          <Button
                            className={classes.button}
                            href={link.subItemBlock.url}
                          >
                            {link.subItemBlock.link}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </Element>
  )
}

const useStyles = makeStyles(theme => ({
  linkTitle: {
    position: "relative",
    cursor: "pointer",
    fontSize: 18,
    margin: "auto",
  },
  subtitleText: {
    marginTop: 40,
  },
  icon: {
    width: 32,
    height: 32,
  },
  subMenu: {
    zIndex: 1,
    display: "block",
    position: "absolute",
    boxShadow: "0px 1px #e6e6e6",
    left: 0,
    width: "100%",
    overflow: "hidden",
    padding: theme.spacing(6, 0),
    top: 80,
    backgroundColor: theme.palette.common.white,
    "& > div": {
      animation: `$bounceMenuItem 700ms cubic-bezier(0.25, 0.1, 0.1, 1)`,
    },
  },
  "@keyframes bounceMenuItem": {
    "0%": {
      opacity: 0,
      transform: "translateY(-300%)",
    },
    "60%": {
      opacity: 1,
      transform: "translateY(8%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  subBlock: ({ subBlockChart }) => ({
    padding: theme.spacing(3),
    backgroundColor: subBlockChart.backgroundColor,
    color: subBlockChart.color,
  }),
  divider: ({ subBlockChart }) => ({
    backgroundColor: subBlockChart.color,
  }),
  button: ({ subBlockChart }) => ({
    backgroundColor: subBlockChart.link.backgroundColor,
    color: subBlockChart.link.color,
    "&:hover": {
      backgroundColor: lighten(subBlockChart.link.backgroundColor, 0.2),
    },
  }),
  item: ({ chart }) => ({
    color: chart.color,
    alignItems: "center",
    lineHeight: "140%",
    textDecoration: "none",
    fontWeight: 500,
    flexDirection: "column",
    display: "flex",
    height: "100%",
    margin: "0 16px",
    "&:hover": {
      color: chart.hover,
    },
    "&:after": {
      transform: "scaleX(0)",
      content: "''",
      display: "block",
      width: "100%",
      bottom: 0,
      transition: "transform 250ms ease-in-out",
      borderBottom: `solid 4px ${chart.hover}`,
    },
    "&:hover:after": {
      transform: "scaleX(1)",
    },
  }),
  action: ({ chart }) => ({
    position: "absolute",
    top: 0,
    color: chart.hover,
    right: -13,
  }),
  link: {
    padding: theme.spacing(2),
    border: "none",
    outline: "0 none",
    wdith: "100%",
    cursor: "pointer",
    borderRadius: 16,
    display: "flex",
    textAlign: "left",
    alignItems: "flex-start",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#F6F6F6",
    },
  },
  title: {
    margin: 0,
    padding: 0,
  },
  subtitle: {
    color: "#787878",
    marginTop: 4,
    "&:hover": {
      color: "black",
    },
  },
}))

export default React.memo(Item)
