import * as React from "react"
import { makeStyles, IconButton, useTheme, Hidden } from "@material-ui/core"

import MobileLogo from "./assets/mobileLogo.svg"

import DesktopLogo from "./assets/desktopLogo.svg"
import MenuIcon from "@material-ui/icons/Menu"
import clsx from "clsx"
import Item from "./Item"
import { MobileMenuDrawer } from "./components/MobileMenuDrawer"
import { AccountDrawer } from "./components/AccountDrawer"

const useThemeFromColor = color => {
  const theme = useTheme()
  const chartColor = {
    Green: {
      color: theme.palette.common.white,
      hover: theme.palette.primary.main,
    },
    Pink: {
      color: theme.palette.secondary.main,
      hover: theme.palette.secondary.main,
    },
    Yellow: {
      color: "#1A8E96",
      hover: theme.palette.secondary.main,
    },
    White: {
      color: theme.palette.secondary.main,
      hover: theme.palette.primary.main,
    },
  }

  return chartColor[color || "Green"]
}

export function Navigation({ navigation, type, displayHeaderBanner = false }) {
  const { links, color } = navigation
  const chart = useThemeFromColor(color)
  const [isSticky, setIsSticky] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isAccountDrawerOpen, setIsAccountDrawerOpen] = React.useState(false)
  const [indexOpen, setIndexOpen] = React.useState(null)
  const classes = useStyles({
    type,
    chart,
    displayHeaderBanner,
    color,
    indexOpen,
  })

  const ref = React.useRef()
  const handleHover = index => () => {
    setIndexOpen(prevState => (prevState === index ? null : index))
  }
  const handleOpenMenu = React.useCallback(() => {
    setIsOpen(prevState => !prevState)
  }, [])

  const scrollListener = React.useCallback(() => {
    if (window.scrollY > ref.current.clientHeight) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }, [ref])
  React.useEffect(() => {
    if (ref.current) {
      document.addEventListener("scroll", scrollListener)
    }
    return () => {
      document.removeEventListener("scroll", scrollListener)
    }
  }, [ref, scrollListener])

  return (
    <div className={classes.container} ref={ref}>
      <div className={classes.menu}>
        <Hidden xsDown implementation="css" className={classes.listItem}>
          <div className={classes.innerListItem}>
            {links.map(({ link }, index) => (
              <Item
                key={index}
                link={link}
                indexOpen={indexOpen}
                chart={chart}
                handleHover={handleHover}
                index={index}
              />
            ))}
          </div>
        </Hidden>
        <a
          className={clsx(
            { [classes.isSticky]: isSticky && type !== "article" },
            classes.logo
          )}
          href="/"
        >
          <Hidden xsDown implementation="css">
            <DesktopLogo className={classes.logoIcon} />
          </Hidden>
          <Hidden smUp implementation="css">
            <MobileLogo className={classes.logoIcon} />
          </Hidden>
        </a>
        <Hidden smUp implementation="css">
          <div className={classes.icon}>
            <IconButton
              size="small"
              aria-label="open-menu"
              onClick={handleOpenMenu}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </div>
        </Hidden>
      </div>
      <MobileMenuDrawer
        open={isOpen}
        links={links}
        setIsAccountDrawerOpen={setIsAccountDrawerOpen}
        handleOpenMenu={handleOpenMenu}
      />
      <AccountDrawer
        open={isAccountDrawerOpen}
        links={links}
        setIsAccountDrawerOpen={setIsAccountDrawerOpen}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  accountIcon: {
    fill: theme.palette.secondary.main,
  },
  container: ({ type, indexOpen, color }) => ({
    position: type === "article" ? "relative" : "absolute",
    top: 0,
    left: 0,
    zIndex: 100,
    width: "100%",
    backgroundColor:
      (indexOpen === 0 || indexOpen === 2 || indexOpen === 1) &&
      color === "Pink"
        ? theme.palette.primary.main
        : "transparent",
  }),
  personIcon: ({ chart }) => ({
    color: chart.hover,
  }),
  accountLinkConnected: {
    color: "white",
    fill: "white",
  },
  account: {
    fontSize: 20,
    fontWeight: 500,
    color: "white",
  },
  profileIcon: ({ chart }) => ({
    color: chart.hover,
    marginLeft: theme.spacing(2),
  }),
  icon: {
    padding: 5,
    background: "white",
    borderRadius: "50%",
  },
  menuIcon: ({ chart }) => ({
    color: chart.hover,
  }),
  profile: ({ chart }) => ({
    color: chart.hover,
  }),
  iconDesktopInner: ({ chart }) => ({
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: 20,
    fontWeight: 500,
    color: chart.color,
    fill: chart.color,
    "&:hover": {
      color: chart.hover,
      fill: chart.hover,
    },
    "& > svg": {
      fontSize: 27,
      color: "inherit",
      marginLeft: theme.spacing(2),
    },
  }),
  iconDesktop: {
    position: "absolute",
    right: 0,
    paddingRight: 40,
  },
  personButton: {
    width: 32,
    padding: 5,
    height: 32,
  },
  logo: ({ chart }) => ({
    color: chart.hover,
    [theme.breakpoints.up("sm")]: {
      width: ({ type }) => (type !== "article" ? 130 : 70),
      height: ({ type }) => (type !== "article" ? 130 : 70),
      transform: "scale(1)",
      transition: "transform .2s linear, top .2s linear",
      zIndex: 4,
      position: ({ type }) => (type !== "article" ? "fixed" : "absolute"),
      left: theme.spacing(5),
      top: ({ displayHeaderBanner }) =>
        displayHeaderBanner ? theme.spacing(10) : theme.spacing(1),
    },
    "@media(max-width: 1150px)": {
      "& svg": {
        width: 50,
        height: 50,
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& svg": {
        width: 40,
        height: 40,
      },
    },
  }),
  menu: {
    height: 80,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      width: "100%",
      padding: 24,
    },
  },
  isSticky: {
    transformOrigin: "top",
    top: ({ displayHeaderBanner }) =>
      displayHeaderBanner ? theme.spacing(10) : theme.spacing(1),
    width: 130,
    height: 130,
    left: 40,
    transform: "scale(0.8) !important",
    transition: "transform .2s linear, top .2s linear",
    "@media(max-width: 1150px)": {
      left: 0,
    },
  },
  iconButton: {
    backgroundColor: "#F6F6F6",
    borderRadius: 50,
    padding: 5,
  },
  clearIcon: {
    fill: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  listItem: {
    height: "100%",
  },
  innerListItem: {
    display: "flex",
    height: "100%",
  },
  logoIcon: {
    width: 80,
    transition: "400ms",
    "&:hover": {
      cursor: "pointer",
      transform: "rotate(360deg)",
      transition: "1000ms",
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  paperDrawer: {
    right: 0,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
  },
  drawerList: {
    boxSizing: "border-box",
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(3),
  },
  drawerListSmall: {
    paddingLeft: theme.spacing(2),
  },
  drawerListItem: {
    borderRadius: 16,
    padding: 16,
  },

  drawerListItemActive: {
    backgroundColor: "#F6F6F6 !important",
  },
  drawerListItemText: {
    marginTop: 0,
    marginBottom: 0,
    "& > span": {
      fontSize: 20,
      fontWeight: 500,
      color: "black",
    },
  },
  drawerListSubItemText: {
    "& > span": {
      color: theme.palette.secondary.main,
      fontSize: 20,
      fontWeight: 500,
      padding: "8px 48px 8px 16px",
    },
  },
  drawerListSubItemIcon: {
    color: theme.palette.secondary.main,
  },
  expandMore: {
    transform: "rotate(90deg)",
    color: theme.palette.common.black,
    fontSize: 35,
    transition: "transform .2s linear, top .2s linear",
  },
  language: {
    display: "flex",
    alignItems: "center",
  },
  mapIcon: {
    marginRight: theme.spacing(),
  },
  bottomActions: {
    backgroundColor: "#F6F6F6",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}))
