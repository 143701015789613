import React from "react"

import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core"

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    margin: "8px 0 16px 0",
    "& input": {
      color: "white",
      padding: 16,
    },
  },
})(TextField)

export const StyledTextField = props => {
  return <CssTextField {...props} />
}
