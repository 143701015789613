import React from "react"
import {
  makeStyles,
  Typography,
  Button,
  Container,
  Hidden,
} from "@material-ui/core"
import useColor from "src/hooks/useColor"
import ImageWithLazyload from "../../utils/components/ImageWithLazyload"

export default function USPBackgroundImage({
  uspImage,
  uspMobileImage,
  title,
  text,
  buttonText,
  buttonLink,
  backgroundColor,
  secondBackgroundColor,
  mobileImage,
}) {
  const topColor = useColor(backgroundColor)
  const bottomColor = useColor(secondBackgroundColor)
  const styles = useStyle({
    topColor,
    bottomColor,
  })
  return (
    <div className={styles.bloc}>
      <Container className={styles.container} maxWidth="md">
        <div className={styles.imageContainer}>
          <Hidden xsDown implementation="css" className={styles.imageHidden}>
            <div className={styles.imagePositionner}>
              <ImageWithLazyload src={uspImage} className={styles.image} />
            </div>
          </Hidden>
          <Hidden smUp implementation="css" className={styles.imageHidden}>
            <div className={styles.imagePositionner}>
              <ImageWithLazyload
                src={uspMobileImage}
                className={styles.image}
              />
            </div>
          </Hidden>
        </div>
        <div className={styles.textContainer}>
          <Typography variant="h2" className={styles.subtitle}>
            {title}
          </Typography>
          <Typography className={styles.body1}>{text}</Typography>
          {buttonText && (
            <Button
              variant="contained"
              color="primary"
              className={styles.blueButton}
              href={buttonLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {buttonText}
            </Button>
          )}
        </div>
      </Container>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  bloc: {
    background: ({ topColor, bottomColor }) =>
      `linear-gradient(${
        topColor === "inherit" ? "transparent" : topColor
      } 50%, ${bottomColor === "inherit" ? "transparent" : bottomColor} 50%)`,
  },
  container: {
    position: "relative",
    padding: 0,
    overflow: "hidden",
  },
  textContainer: {
    color: "white",
    position: "absolute",
    zIndex: 2,
    left: 100,
    bottom: 50,
    right: "30%",
    maxWidth: "75%",
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(5),
      bottom: theme.spacing(3),
      left: theme.spacing(5),
      maxWidth: "unset",
    },
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    fontSize: 44,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
    },
  },
  body1: {
    marginBottom: theme.spacing(3),
  },
  imageContainer: {
    width: "calc(100% - 32px)",
    height: 400,
    margin: "auto",
    overflow: "hidden",
  },
  image: {
    minWidth: "100%",
    minHeight: "100%",
  },
  imageHidden: {
    width: "100%",
    height: "100%",
  },
  imagePositionner: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))
