import * as React from "react"

export const GoogleAutoComplete = ({
  children,
  onChange,
  id = "google-autocomplete",
}) => {
  const input = React.cloneElement(children, {
    ...children.props,
    id,
  })
  const handlePlaceChange = React.useCallback(
    autocomplete => () => {
      const place = autocomplete.getPlace()
      if (place) {
        onChange(place)
      }
    },
    [onChange]
  )

  React.useEffect(() => {
    if (!window.google) {
      return null
    }
    const element = document.getElementById(id)
    const autocomplete = new window.google.maps.places.Autocomplete(element, {
      types: ["address"],
      fields: ["formatted_address", "address_components"],
      componentRestrictions: { country: "fr" },
    })

    const eventListener =
      window &&
      window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        handlePlaceChange(autocomplete)
      )

    if (window.MutationObserver) {
      const observerHack = new MutationObserver(function() {
        observerHack.disconnect()
        element.autocomplete = "disable-autofill"
      })
      observerHack.observe(element, {
        attributes: true,
        attributeFilter: ["autocomplete"],
      })

      return () => {
        observerHack.disconnect()
        window.google.maps.event.removeListener(eventListener)
      }
    } else {
      return () => {
        window.google.maps.event.removeListener(eventListener)
      }
    }
  }, [handlePlaceChange, id])

  return input
}
