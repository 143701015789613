import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import EmailForm from "src/utils/components/emailForm"

export default function NewsletterCallToActionBloc({
  title,
  text,
  emailPlaceholder,
  buttonText,
  isBackgroundWhite,
  optInText,
}) {
  const styles = useStyle({ isBackgroundWhite })
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <Typography variant="h3" color="secondary" className={styles.title}>
          {title}
        </Typography>
        <Typography variant="body2" className={styles.subtext}>
          {text}
        </Typography>
      </div>
      <div className={styles.embeddedSubscribe}>
        <EmailForm
          submitText={buttonText}
          bloc="newsletter"
          placeholder={emailPlaceholder}
          optInTextRgpd={optInText}
        />
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  container: {
    margin: "auto",
    width: "min(1034px , 90%)",
    borderRadius: 9,
    backgroundColor: ({ isBackgroundWhite }) =>
      isBackgroundWhite ? "#F6F6F6" : theme.palette.secondary.main,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing(),
    color: ({ isBackgroundWhite }) =>
      isBackgroundWhite
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
  },
  text: {
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: theme.spacing(2),
      textAlign: "center",
    },
  },
  embeddedSubscribe: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  signupScroll: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}))
