export const REPAIR_STORAGE = "murfy_rebranding_repair_storage"

export const repairStorage = {
  setAddress: function(address) {
    const postalCode = address.address_components
      ? address.address_components.find(component =>
          component.types.includes("postal_code")
        )?.long_name
      : null
    const repairStorage = JSON.parse(this.get())
    repairStorage["address"] = address
    repairStorage["postalCode"] = postalCode
    try {
      sessionStorage.setItem(REPAIR_STORAGE, JSON.stringify(repairStorage))
    } catch (e) {
      console.log(e)
    }
  },
  setProduct: function(product) {
    const repairStorage = JSON.parse(this.get())
    if (product.name === "multi_product") {
      repairStorage["productName"] = product.name
    } else {
      repairStorage["product"] = product._id
      repairStorage["productName"] = product.name
    }
    try {
      sessionStorage.setItem(REPAIR_STORAGE, JSON.stringify(repairStorage))
    } catch (e) {
      console.log(e)
    }
  },
  get: function() {
    try {
      const storage = sessionStorage.getItem(REPAIR_STORAGE)
      if (!storage) {
        return "{}"
      }
      return storage
    } catch (e) {
      console.log(e)
      return "{}"
    }
  },
  remove: function() {
    try {
      sessionStorage.removeItem(REPAIR_STORAGE)
    } catch (e) {
      console.log(e)
    }
  },
}
