import * as React from "react"
import { TextField, makeStyles } from "@material-ui/core"

export const RoundedInput = ({
  children,
  InputProps,
  backgroundColor,
  flat = false,
  height,
  autoFocus,
  ...props
}) => {
  const classes = useStyle({ flat, backgroundColor, height })

  return (
    <TextField
      autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
      variant="outlined"
      {...props}
      inputProps={{ className: classes.input }}
      InputProps={{
        ...InputProps,
        classes: {
          root: classes.root,
          focused: classes.focused,
          notchedOutline: classes.notchedOutline,
        },
      }}
    />
  )
}

const useStyle = makeStyles(theme => ({
  input: {
    padding: "18px 18px !important",
  },
  root: {
    height: ({ flat, height }) => height || (flat ? 20 : "auto"),
    borderRadius: 42,
    color: "#787878",
    fontSize: 16,
    backgroundColor: ({ backgroundColor }) => backgroundColor || "inherit",
    "& > input": {
      "&::placeholder": {
        color: "#787878",
        opacity: 1,
      },
    },
    "& > fieldset": {
      borderColor: ({ flat }) => (flat ? "transparent" : "#DBDFE8"),
    },
    "&:hover": {
      backgroundColor: ({ flat }) => (flat ? "transparent" : "#F6F6F6"),
      "& > fieldset": {
        borderColor: ({ flat }) =>
          flat ? "transparent" : "#DBDFE8 !important",
      },
    },
    "& > .MuiSelect-icon": {
      color: "#A4E7DB",
      right: 10,
    },
  },
  notchedOutline: ({ flat }) => ({
    border: flat ? "none" : "solid 1px #DBDFE8 !important",
  }),
  focused: ({ flat }) => ({
    boxShadow: flat ? "transparent" : "0px 4px 16px rgba(0, 0, 0, 0.1)",
    "& > fieldset": {
      border: flat ? "none" : "solid 1px #DBDFE8 !important",
    },
  }),
}))
