import React from "react"
import { makeStyles, Button, Typography, Link, Box } from "@material-ui/core"
import SubscribeNewsletter from "src/utils/SubscribeNewsletter"

import Logo from "./assets/footer_logo.svg"
import Facebook from "./assets/facebook.svg"
import Youtube from "./assets/youtube.svg"
import Instagram from "./assets/instagram.svg"
import Linkedin from "./assets/linkedin.svg"
import Twitter from "./assets/twitter.svg"

function CustomLink({ link, className, type, subClassName }) {
  const Tag = type || "span"
  return (
    <Link
      href={link.url}
      target={link.openLiknInNewTab ? "_blank" : "_self"}
      rel="noopener noreferrer"
      className={className}
    >
      <Tag className={subClassName}> {link.title}</Tag>
    </Link>
  )
}

const socials = [
  {
    logo: Instagram,
    url: "https://www.instagram.com/murfy.fr/",
  },
  {
    logo: Facebook,
    url: "https://www.facebook.com/Murfy.fr/",
  },
  {
    logo: Youtube,
    url: "https://www.youtube.com/c/MurfyFrance",
  },
  {
    logo: Linkedin,
    url: "https://www.linkedin.com/company/murfy-repair/",
  },
  {
    logo: Twitter,
    url: "https://twitter.com/MurfyFR",
  },
]

export default function Footer({ cgv, navigationBlocks }) {
  const classes = useStyle()

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.logo}>
          <a href="/">
            <Logo />
          </a>
        </div>

        <div className={classes.subcontent}>
          <div className={classes.lists}>
            {navigationBlocks.map(({ navigationBlock }) => (
              <div>
                <Typography variant="body2" className={classes.title}>
                  {navigationBlock.footerBlockTitle}
                </Typography>
                <ul>
                  {navigationBlock.footerBlockLinks.map(
                    ({ footerBlockLink }) => (
                      <CustomLink
                        key={footerBlockLink.title}
                        link={footerBlockLink}
                        type="li"
                        subClassName={`${classes.item}`}
                      />
                    )
                  )}
                </ul>
              </div>
            ))}
          </div>
          <div className={classes.contacts}>
            <Box mb={4} width={"100%"}>
              <Button
                variant="contained"
                className={classes.button}
                href="https://murfy.zendesk.com/hc/fr/"
                target="_blank"
                rel="noopener"
              >
                Besoin d'aide
              </Button>
            </Box>
            <Typography variant="small" className={classes.item}>
              Pour recevoir les astuces Murfy,{" "}
              <span className={classes.title}>
                inscrivez-vous à la newsletter
              </span>
            </Typography>
            <SubscribeNewsletter />
            <div className={classes.socials}>
              {socials.map(({ logo, url }) => {
                return (
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={url}
                  >
                    {logo()}
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.legals__container}>
        <div className={classes.credits}>
          <Typography variant="caption">
            © {new Date().getFullYear()} - Murfy
          </Typography>
        </div>
        <div className={classes.legals}>
          {cgv.map(cgvLink => (
            <Box mb={1}>
              <CustomLink link={cgvLink} />
            </Box>
          ))}
        </div>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.secondary.dark,
    width: "100%",
    color: "white",
    padding: "64px 64px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },

    "& a": {
      textDecoration: "none",
      color: "inherit",
    },

    "& ul": {
      padding: "0",
      margin: "0",
    },
  },
  legals: {
    display: "flex",
    alignItems: "flex-start",
    "& a": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  contacts: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    minWidth: 260,
    width: 260,
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      marginTop: theme.spacing(3),
      maxWidth: "unset",
    },
    "& a": {
      textDecoration: "none",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  subcontent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  contactLink: {
    marginTop: theme.spacing(),
    fontWeight: 700,
    textAlign: "end",
  },
  logo: {
    marginRight: 40,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
      alignSelf: "center",
      margin: "auto",
    },
    "& svg": {
      transition: "400ms",
      height: 80,
      width: 80,
      "&:hover": {
        cursor: "pointer",
        transform: "rotate(360deg)",
        transition: "1000ms",
      },
    },
  },
  lists: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridColumnGap: theme.spacing(7),
    marginRight: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
      gridRowGap: theme.spacing(5),
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      marginRight: "unset",
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "140%",
    margin: "0 0 24px 0",
  },
  item: {
    listStyleType: "none",
    color: "white",
    textDecoration: "none",
    marginBottom: "8px",
  },
  button: {
    width: "80%",
    background: "linear-gradient(90deg, #F64F6E 0%, #FF8787 100%)",
    padding: "9px 15px",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      width: 250,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  socials: {
    display: "flex",
    alignSelf: "flex-start",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: 250,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    justifyContent: "space-between",
    "& a": {
      "& svg": {
        width: 25,
        height: 25,
      },
    },
  },
  legals__container: {
    marginTop: "115px",
    display: "flex",
    padding: "0 0 0 10px",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginTop: "50px",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
    },
    "& span": {
      color: "white",
    },
    "& a": {
      fontSize: "13px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 40px 0 0px",
      },
    },
  },
  itemColor: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
}))
