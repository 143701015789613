import React from "react"

import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import Arrow from "../assets/arrow.svg"
import clsx from "clsx"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

export function MobileMenuDrawer({
  links,
  open,
  setIsAccountDrawerOpen,
  handleOpenMenu,
}) {
  const classes = useStyles()
  const [mobileSubMenuOpen, setMobileSubMenuOpen] = React.useState(null)

  const handleOpen = React.useCallback(
    (index, isDirectLink, link) => () => {
      if (!isDirectLink) {
        setMobileSubMenuOpen(prevState => (prevState === index ? null : index))
      } else {
        window.location.pathname = link
      }
    },
    []
  )
  return (
    <Drawer
      PaperProps={{ className: classes.paperDrawer }}
      open={open}
      anchor="right"
    >
      <Box
        pt={3}
        px={3}
        pb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton onClick={handleOpenMenu} className={classes.iconButton}>
          <ClearIcon className={classes.clearIcon} />
        </IconButton>
        {links
          .filter(item => item.link.picto)
          .map(({ link }, index) => {
            return (
              <Box onClick={() => setIsAccountDrawerOpen(true)}>
                <img width={24} src={link.picto.publicURL} />
              </Box>
            )
          })}
      </Box>
      <div className={classes.scrollablePanel}>
        <List component="nav" className={classes.drawerList}>
          {links
            .filter(item => !!item.link.title)
            .map(({ link }, index) => {
              return (
                <div key={index}>
                  <ListItem
                    button
                    onClick={handleOpen(
                      index,
                      !Boolean(link.menuItem),
                      link.url
                    )}
                    key={index}
                    className={clsx(
                      {
                        [classes.drawerListItemActive]:
                          mobileSubMenuOpen === index,
                      },
                      classes.drawerListItem
                    )}
                  >
                    <ListItemText
                      primary={link.title}
                      className={classes.drawerListItemText}
                    />
                    <Arrow
                      className={clsx({
                        [classes.expandLess]:
                          link.menuItem?.length > 0 &&
                          mobileSubMenuOpen === index,
                        [classes.expandMore]: link.menuItem?.length > 0,
                      })}
                    />
                  </ListItem>
                  <Collapse in={mobileSubMenuOpen === index}>
                    <Box pt={1} pb={1}>
                      <List component="nav" className={classes.drawerListSmall}>
                        {link.menuItem?.map((menuItem, subIndex) => {
                          return (
                            <a
                              href={menuItem.link}
                              key={`${index} ${subIndex}`}
                            >
                              <ListItem button key={`${index} ${subIndex}`}>
                                <ListItemText
                                  primary={menuItem.title}
                                  className={classes.drawerListSubItemText}
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    size="small"
                                    edge="end"
                                    aria-label="go to"
                                  >
                                    <ChevronRightIcon
                                      className={classes.drawerListSubItemIcon}
                                    />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            </a>
                          )
                        })}
                      </List>
                    </Box>
                  </Collapse>
                </div>
              )
            })}
        </List>
      </div>
    </Drawer>
  )
}
const useStyles = makeStyles(theme => ({
  expandLess: {
    transform: "rotate(270deg) !important",
  },
  scrollablePanel: {
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  iconButton: {
    backgroundColor: "#F6F6F6",
    borderRadius: 50,
    padding: 5,
  },
  clearIcon: {
    fill: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  paperDrawer: {
    right: 0,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
  },
  drawerList: {
    boxSizing: "border-box",
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(3),
  },
  drawerListSmall: {
    paddingLeft: theme.spacing(2),
  },
  drawerListItem: {
    borderRadius: 16,
    padding: 16,
  },

  drawerListItemActive: {
    backgroundColor: "#F6F6F6 !important",
  },
  drawerListItemText: {
    marginTop: 0,
    marginBottom: 0,
    "& > span": {
      fontSize: 20,
      fontWeight: 500,
      color: "black",
    },
  },
  drawerListSubItemText: {
    "& > span": {
      color: theme.palette.secondary.main,
      fontSize: 20,
      fontWeight: 500,
      padding: "8px 48px 8px 16px",
    },
  },
  drawerListSubItemIcon: {
    color: theme.palette.secondary.main,
  },
  expandMore: {
    transform: "rotate(90deg)",
    color: theme.palette.common.black,
    fontSize: 35,
    transition: "transform .2s linear, top .2s linear",
  },
}))
