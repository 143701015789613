import React from "react"

import {
  Box,
  Drawer,
  IconButton,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import clsx from "clsx"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

export function AccountDrawer({ links, open, setIsAccountDrawerOpen }) {
  const classes = useStyles()

  return (
    <Drawer open={open} anchor="right">
      <Box
        pt={3}
        px={3}
        pb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100vw"
      >
        <IconButton
          onClick={() => setIsAccountDrawerOpen(false)}
          className={classes.iconButton}
        >
          <ClearIcon className={classes.clearIcon} />
        </IconButton>
      </Box>
      <Box p={3}>
        <ListItem
          button
          className={clsx(classes.drawerListItemActive, classes.drawerListItem)}
        >
          <ListItemText
            primary="Mon espace Murfy"
            className={classes.drawerListItemText}
          />
        </ListItem>
        {links
          .find(item => !!item.link.picto)
          ?.link.menuItem?.map((menuItem, subIndex) => {
            return (
              <Box
                button
                onClick={() => (window.location.pathname = menuItem.link)}
                key={subIndex}
                px={3}
                pt={3}
              >
                {menuItem.title}
                <IconButton size="small" edge="end" aria-label="go to">
                  <ChevronRightIcon className={classes.drawerListSubItemIcon} />
                </IconButton>
              </Box>
            )
          })}
      </Box>
    </Drawer>
  )
}
const useStyles = makeStyles(theme => ({
  expandLess: {
    transform: "rotate(270deg) !important",
  },
  scrollablePanel: {
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  iconButton: {
    backgroundColor: "#F6F6F6",
    borderRadius: 50,
    padding: 5,
  },
  clearIcon: {
    fill: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  paperDrawer: {
    right: 0,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
  },
  drawerList: {
    boxSizing: "border-box",
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(3),
  },
  drawerListSmall: {
    paddingLeft: theme.spacing(2),
  },
  drawerListItem: {
    borderRadius: 16,
    padding: 16,
  },

  drawerListItemActive: {
    backgroundColor: "#F6F6F6 !important",
  },
  drawerListItemText: {
    marginTop: 0,
    marginBottom: 0,
    "& > span": {
      fontSize: 20,
      fontWeight: 500,
      color: "black",
    },
  },
  drawerListSubItemText: {
    "& > span": {
      color: theme.palette.secondary.main,
      fontSize: 20,
      fontWeight: 500,
      padding: "8px 48px 8px 16px",
    },
  },
  drawerListSubItemIcon: {
    color: theme.palette.secondary.main,
  },
}))
