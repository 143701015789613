import React from "react"
import showdown from "showdown"

import { makeStyles, Container, Typography, Link } from "@material-ui/core"
const converter = new showdown.Converter()

export default function LinksBlock({ links, title, subtitle, isArticle }) {
  const styles = useStyle({ isArticle })
  return (
    <div className={styles.LinksBlockContainer}>
      <Container maxWidth="md" className={styles.LinksBlockContent}>
        <Typography
          variant="h2"
          align={isArticle ? "center" : "left"}
          color="secondary"
        >
          {title}
        </Typography>
        <div className={styles.linksWrapper}>
          {links.map((link, index) => (
            <Link
              align={isArticle ? "center" : "left"}
              className={styles.link}
              key={index}
              underline="none"
              href={link.url}
            >
              {link.title}
            </Link>
          ))}
        </div>
        <Typography
          variant="caption"
          className={styles.body1}
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(subtitle) }}
        />
      </Container>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  LinksBlockContainer: {
    width: "100%",
    backgroundColor: ({ isArticle }) => (isArticle ? "white" : "#F6F6F6"),
    paddingTop: 80,
    paddingBottom: 60,
  },
  link: {
    color: theme.palette.text.turquoise,
    fontWeight: 700,
    fontSize: 17,
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  linksWrapper: {
    marginTop: 50,
    marginBottom: 50,
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridRowGap: ({ isArticle }) => (isArticle ? 30 : 0),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  body1: {
    "& a": {
      color: theme.palette.yellow,
      textDecoration: "underline",
    },
  },
}))
