export const getTrackerField = (fieldName = "clientId") => {
  if (!window.ga || typeof window.ga.getAll !== "function") {
    return ""
  }

  const trackers = window.ga.getAll()
  if (trackers.length === 0) {
    return ""
  }

  return trackers[0].get(fieldName)
}
