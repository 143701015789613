import React from "react"
import PropTypes from "prop-types"
import showdown from "showdown"
import cx from "classnames"
import { makeStyles, Container } from "@material-ui/core"

const converter = new showdown.Converter()

function getMarkdownStyle(pageType, styles) {
  if (pageType === "landingPage") {
    return styles.markdownContentLandingPage
  } else if (pageType === "article") {
    return styles.markdownContentArticle
  } else {
    return styles.markdownContentNoPadding
  }
}

const MarkdownContent = ({
  content,
  className,
  pageType,
  hasCustomColor,
  customColor,
}) => {
  const styles = useStyles({
    customColor,
    hasCustomColor,
  })
  return (
    <Container maxWidth="md">
      <div
        className={cx(className, getMarkdownStyle(pageType, styles))}
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
      />
    </Container>
  )
}

MarkdownContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}

export default MarkdownContent

const useStyles = makeStyles(theme => ({
  markdownContentArticle: {
    color: ({ color, hasCustomColor }) => (hasCustomColor ? color : "inherit"),
    "& h1": theme.typography.h1,
    "& h2": theme.typography.h2,
    "& h3": theme.typography.h3,
    "& p": {
      ...theme.typography.body1,
      "& img": {
        maxWidth: 680,
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
        },
      },
    },

    "& blockquote": {
      borderLeft: `6px solid ${theme.palette.yellow}`,
      paddingLeft: 40,
      marginLeft: 0,

      "& p": {
        marginTop: 4,
        marginBottom: 4,
      },
      "& h2": {
        marginTop: 4,
        marginBottom: 4,
      },
      "& h3": {
        marginTop: 4,
        marginBottom: 4,
      },
    },

    "& a": {
      color: ({ color, hasCustomColor }) =>
        hasCustomColor ? color : theme.palette.yellow,
      textDecoration: "underline",
    },
    "& ul": {
      backgroundColor: theme.palette.containerGrey,
      padding: "16px 48px",
      margin: 0,

      "& li": {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
  },

  markdownContentLandingPage: {
    color: ({ customColor, hasCustomColor }) =>
      hasCustomColor ? customColor : "inherit",
    "& h3": theme.typography.h3,
    "& p": {
      ...theme.typography.body1,
      "& img": {
        maxWidth: 680,
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
        },
      },
    },

    "& blockquote": {
      borderLeft: `6px solid ${theme.palette.yellow}`,
      paddingLeft: 40,
      marginLeft: 0,

      "& p": {
        marginTop: 4,
        marginBottom: 4,
      },
      "& h2": {
        marginTop: 4,
        marginBottom: 4,
      },
      "& h3": {
        marginTop: 4,
        marginBottom: 4,
      },
    },

    "& a": {
      color: theme.palette.yellow,
      textDecoration: "underline",
    },

    "& h1": {
      color: ({ color, hasCustomColor }) =>
        hasCustomColor ? color : theme.palette.yellow,
    },

    "& h2": {
      color: ({ customColor, hasCustomColor }) =>
        hasCustomColor ? customColor : theme.palette.text.primary,
    },
  },

  markdownContentNoPadding: {
    color: ({ customColor, hasCustomColor }) =>
      hasCustomColor ? customColor : "inherit",
    "& h1": theme.typography.h1,
    "& h2": theme.typography.h2,
    "& h3": theme.typography.h3,
    "& p": {
      ...theme.typography.body1,
      "& img": {
        maxWidth: 680,
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
        },
      },
    },

    "& blockquote": {
      borderLeft: `6px solid ${theme.palette.yellow}`,
      paddingLeft: 40,
      marginLeft: 0,

      "& p": {
        marginTop: 4,
        marginBottom: 4,
      },
      "& h2": {
        marginTop: 4,
        marginBottom: 4,
      },
      "& h3": {
        marginTop: 4,
        marginBottom: 4,
      },
    },

    "& a": {
      color: ({ customColor, hasCustomColor }) =>
        hasCustomColor ? customColor : theme.palette.yellow,
      textDecoration: "underline",
    },
  },
}))
