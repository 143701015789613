import * as React from "react"
import PropTypes from "prop-types"
import styles from "./blockContainer.module.scss"

const BlockContainer = ({ children, overflowTop, noMargin }) => {
  const ref = React.useRef(null)
  const getRef = React.useCallback(() => {
    return ref.current
  }, [ref])

  return (
    <div
      className={
        noMargin
          ? ""
          : overflowTop
          ? styles.blockContainerOverflowTop
          : styles.blockContainer
      }
      ref={ref}
    >
      {React.cloneElement(children, { ...children.props, getRef })}
    </div>
  )
}

BlockContainer.propTypes = {
  children: PropTypes.any.isRequired,
}

export default BlockContainer
