import React, { useState } from "react"

import { withStyles } from "@material-ui/core/styles"
import { getTrackerField } from "../ga"
import { subscribe } from "../../helpers/subscribe"

import {
  CircularProgress,
  makeStyles,
  Typography,
  InputBase,
  Button,
  Checkbox,
  ButtonBase,
} from "@material-ui/core"

const CircularProgressStyled = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5,
    color: theme.palette.primary,
    marginLeft: 5,
  },
}))(CircularProgress)

export default function EmailForm({
  submitText,
  optInTextRgpd,
  placeholder,
  bloc,
}) {
  const styles = useStyles({ bloc })
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const [email, setEmail] = useState("")
  const [optInOffers, setOptinOffers] = useState(false)

  function handleSubscribe() {
    if (email) {
      setError(null)
      setIsLoading(true)
      let signed_up_at = new Date()
      const data = {
        identifyData: {
          id: email,
          email: email,
          "Optin RH": optInOffers ? "subscribed" : "",
        },
        subscribeData: {
          "Optin NL": "subscribed",
          source: `API-Page - ${window.location?.pathname}`,
          signed_up_at: signed_up_at.toISOString(),
          client_id_google: getTrackerField(),
          welcome_sequence: "",
        },
      }

      subscribe(data).then(response => {
        if (response.status === 200) {
          // Push a tracking event
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "GAevent",
            category: "newsletter_subscribe",
            action: "Banner",
            label: optInOffers ? "Newsletter+Offers" : "Newsletter",
          })

          setEmail("")
          setSuccess(true)
          setOptinOffers(false)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          setError(
            response.data.title ||
              "Nous avons rencontré une erreur, merci de réessayer"
          )
          console.error(response)
        }
      })
    } else {
      setError("Merci de renseigner votre email")
    }
  }

  function handleChangeOptinOffers() {
    setOptinOffers(prev => !prev)
  }

  return (
    <div className={styles.signupWrapper}>
      <div className={styles.signupScroll}>
        <InputBase
          type="email"
          name="EMAIL"
          autoComplete="email"
          className={styles.email}
          id="mceEmail"
          placeholder={placeholder || "Votre adresse mail"}
          value={email}
          onChange={e => {
            setEmail(e.target.value)
            setError(null)
          }}
        />
        <div className={styles.buttonContainer}>
          {isLoading ? (
            <CircularProgressStyled className={styles.loader} />
          ) : success ? (
            <Typography className={styles.success}>✓</Typography>
          ) : (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              name="subscribe"
              onClick={handleSubscribe}
              className={styles.goButton}
            >
              {submitText}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.checkcontainer}>
        <ButtonBase
          className={styles.consentButton}
          disableRipple
          onClick={handleChangeOptinOffers}
        >
          <Checkbox
            className={styles.checkbox}
            color="primary"
            disableRipple
            checked={optInOffers}
          />
          <Typography component="span" className={styles.consentSpan}>
            {optInTextRgpd || "Je souhaite recevoir les offres de Murfy"}
          </Typography>
        </ButtonBase>
      </div>
      {error && (
        <Typography className={styles.error} variant="caption" color="error">
          {error}
        </Typography>
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  goButton: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  text: {
    marginTop: theme.spacing(),
  },
  consentButton: {
    display: "flex",
    alignItems: "center",
  },
  success: {
    color: theme.palette.secondary.main,
    fontSize: 22,
    marginRight: theme.spacing(),
  },
  consentSpan: {
    fontSize: 13,
    textAlign: "left",
  },
  error: {
    position: "absolute",
    bottom: -15,
    left: theme.spacing(2),
  },
  checkbox: {
    "& path": {
      fill: ({ bloc }) =>
        bloc === "newsletter" ? theme.palette.secondary : "white",
    },
  },
  checkcontainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  signupWrapper: {
    textAlign: "left",
    display: "flex",
    justifyContent: "left",
    flexDirection: "column",
    position: "relative",
    width: "70%",
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  optinCheckbox: {
    marginLeft: 0,
  },
  email: {
    backgroundColor: "white",
    borderRadius: 12,
    paddingLeft: theme.spacing(2),
    height: 60,
    width: "100%",
  },
  buttonContainer: {
    position: "absolute",
    top: theme.spacing(),
    bottom: theme.spacing(),
    display: "flex",
    alignItems: "center",
    right: theme.spacing(2),
  },
  signupScroll: {
    position: "relative",
  },
}))
