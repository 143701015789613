import React from "react"
import Img from "gatsby-image"

const ImageWithLazyload = data => {
  const { src, alt, className } = data
  if (!src) {
    return null
  }

  if (src.childImageSharp) {
    const imgProps = {
      alt,
      className,
      placeholderStyle: { filter: "blur(15px)" },
    }

    // Fixed gatsby-image
    if (src.childImageSharp.fixed) {
      return <Img {...imgProps} fixed={src.childImageSharp.fixed} />
    }

    // Fluid gatsby-image
    if (src.childImageSharp.fluid) {
      return <Img {...imgProps} fluid={src.childImageSharp.fluid} />
    }
  }

  // Otherwise, default to img tag
  return <img src={src.publicURL} alt={alt} className={className} />
}

export default ImageWithLazyload
