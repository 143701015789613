import React from "react"
import {
  makeStyles,
  Button,
  CircularProgress,
  withStyles,
  Box,
  Typography,
} from "@material-ui/core"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { StyledTextField } from "./StyledTextField"
import { getTrackerField } from "./ga"
import { subscribe } from "../helpers/subscribe"

const validateSchema = Yup.object().shape({
  email: Yup.string()
    .email("Cette adresse email n'est pas valide")
    .required("Merci de remplir ce champ"),
})

const SubscribeNewsletter = () => {
  const handleSubscribeNewsletter = async (
    values,
    { setSubmitting, setStatus, resetForm }
  ) => {
    let signed_up_at = new Date()
    const data = {
      identifyData: {
        id: values.email,
        email: values.email,
      },
      subscribeData: {
        "Optin NL": "subscribed",
        source: `API-Page - ${window.location?.pathname}`,
        signed_up_at: signed_up_at.toISOString(),
        client_id_google: getTrackerField(),
        welcome_sequence: "",
      },
    }
    return subscribe(data).then(response => {
      if (response.status === 200) {
        resetForm({ email: "" })
        setStatus({ success: true })
      } else {
        setStatus({ success: false })
        setSubmitting(false)
        resetForm({ email: "" })
        console.error(response)
      }
    })
  }
  const classes = useStyles()
  const StyledButton = () => (
    <Button type="submit" className={classes.button} variant="contained">
      Ok
    </Button>
  )

  const CircularProgressStyled = withStyles(theme => ({
    root: {
      height: 10,
      borderRadius: 5,
      color: theme.palette.primary,
      marginLeft: 5,
    },
  }))(CircularProgress)

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={handleSubscribeNewsletter}
      validationSchema={validateSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field name="email">
            {({ field: { name, onChange, value } }) => {
              return (
                <Box className={classes.container}>
                  <StyledTextField
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder="Email"
                    InputProps={{
                      endAdornment: isSubmitting ? (
                        <CircularProgressStyled />
                      ) : (
                        <StyledButton />
                      ),
                    }}
                  />
                  <Typography variant="caption" className={classes.errorMsg}>
                    <ErrorMessage name="email" />
                  </Typography>
                </Box>
              )
            }}
          </Field>
        </Form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    background: "linear-gradient(90deg, #F64F6E 0%, #FF8787 100%)",
    padding: "9px 15px",
    color: "white",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  errorMsg: {
    color: "red",
    marginBottom: theme.spacing(1),
  },
}))

export default SubscribeNewsletter
